// SchumannContainer.js
import React from 'react';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import SchumannLetters from './SchumannLetters'; // Ensure the import path is correct
import SchumannDepression from './SchumannDepression';
import SchumannHappiness from './SchumannHappiness';
import SchumannMusicalWorks from './SchumannMusicalWorks';

const SchumannContainer = () => {
    return (
        <>
            <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-indigo-600 mb-4">Robert Schumann</h1>
                <h2 className="text-2xl text-indigo-500 mb-8">Letters</h2>
                {/* Sub-Navigation for CPE Section */}
                <div className="flex flex-col md:flex-row justify-center">
                    <NavLink
                        to="letters"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Explore Letters
                    </NavLink>
                    <NavLink
                        to="depression"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Depression
                    </NavLink>
                    <NavLink
                        to="happiness"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Happiness
                    </NavLink>
                    <NavLink
                        to="works"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Musical Works
                    </NavLink>
                </div>
            </div>
            <Routes>
                <Route path="letters" element={<SchumannLetters />} />
                <Route path="depression" element={<SchumannDepression />} />
                <Route path="happiness" element={<SchumannHappiness />} />
                <Route path="works" element={<SchumannMusicalWorks />} />
                <Route index element={<Navigate replace to="letters" />} />
            </Routes>
        </>
    );
};

export default SchumannContainer;
