// SchumannContainer.js
import React from 'react';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import DeLaraAudio from './DeLaraAudio';
import EibenshuetzAudio from './EibenschuetzAudio';
import BernsteinAudio from './BernsteinAudio';

const AudioContainer = () => {
    return (
        <>
            <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-indigo-600 mb-4">Advanced Audio Transcription & Analysis</h1>
                {/* Sub-Navigation for CPE Section */}
                <div className="flex flex-col md:flex-row justify-center">
                    <NavLink
                        to="de-lara"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Adelina de Lara - Interview
                    </NavLink>
                    <NavLink
                        to="eibenshuetz"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Ilona Eibenschütz - Interview
                    </NavLink>
                    <NavLink
                        to="bernstein"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Leonard Bernstein - Lecture
                    </NavLink>
                </div>
            </div>
            <Routes>
                <Route path="de-lara" element={<DeLaraAudio />} />
                <Route path="eibenshuetz" element={<EibenshuetzAudio />} />
                <Route path="bernstein" element={<BernsteinAudio />} />
                <Route index element={<Navigate replace to="de-lara" />} />
            </Routes>
        </>
    );
};

export default AudioContainer;
