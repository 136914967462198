import React from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import PITAORThesis from './PITAORThesis';
import FolkThesis from './FolkThesis';


const CPEContainer = () => {
  return (
    <>
      <div className="text-center mb-10">
        <h1 className="text-4xl font-bold text-indigo-600 mb-4">Thesis Topic Brainstorm</h1>
        {/* Sub-Navigation for CPE Section */}
        <div className="flex flex-col md:flex-row justify-center">
        <NavLink
          to="pitaor"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Performing Music in the Age of Recording
        </NavLink>
        <NavLink
          to="folk"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Folk-Music in Art-Music
        </NavLink>
        </div>
      </div>
      <Routes>
        <Route path="pitaor" element={<PITAORThesis />} />
        <Route path="folk" element={<FolkThesis />} />
        <Route index element={<Navigate replace to="pitaor" />} />
      </Routes>
    </>
  );
};

export default CPEContainer;
