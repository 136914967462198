import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

const NiedtChapterCards = () => {
  const [openChapter, setOpenChapter] = useState(null);

  // Array of chapter content with Markdown
  const chapters = [
    { id: 1, title: 'Chapter I: On the Etymology of the General Bass, or from where it is thus named', "content": "The word \"Bass\" is derived by some from the Greek word \"Basis\" meaning the foundation or ground of a thing. Others derive it from the old Latin word \"Bassus\", meaning \"Profundus\" or deep.\n\nWhen the word \"Bass\" is taken alone, it is understood to mean the ground voice in music or any bass which holds the deepest tone, whether this bass is sung or played on a violin, bassoon, trombone or other instruments (as it stands written in single notes).\n\nBut when one says \"General Bass\", it is properly and actually understood to mean such a bass that is played on the organ or on another keyboard with both hands at the same time, so that all or nearly all the other voices of the music sound together in this single one.\n\nIt is also otherwise called \"Basso Continuo\", or in Italian ending \"Basso Continuo\", because it plays continuously while the other voices pause now and then. But nowadays this bass also often pauses, especially in arias and artfully composed secular things, and any violin bass might have the name of a \"Basso Continuo\", so the name \"General Bass\" seems to be more suitable here." },
    { id: 2, title: 'Chapter II: On the Definition or Description of the General Bass', "content": "The General Bass is defined as the most perfect foundation of music, played on a keyboard instrument with both hands. The left hand plays the prescribed bass notes, while the right hand adds consonances and dissonances above them to create a well-sounding harmony. This is done for the glory of God and the permissible delight of the senses.\n\nThe term \"most perfect foundation\" distinguishes the General Bass from other bass lines played on instruments like violins or bassoons. While these are also fundamental voices, they are not as complete as the General Bass since they can only produce one note at a time. In contrast, a skilled organist can use both hands and even the feet to play the General Bass, demonstrating their artistic abilities.\n\nThe description of the General Bass also mentions consonances and dissonances, which can be further categorized:\n\n*   **Perfect Consonances:** Intervals that always sound harmonious and cannot be altered, such as the Fifth and the Octave. The Fourth is sometimes included here.\n*   **Imperfect Consonances:** The Third and Sixth intervals, which can be either major or minor and still sound concordant.\n*   **Perfect Dissonances:** Intervals that require preparation by being heard beforehand and then resolving into a consonance, such as the Second, Seventh, and Fourth.\n*   **Imperfect Dissonances:** Artificially raised or lowered intervals, including the minor Second, augmented Fourth (or diminished Fifth), and minor and major Seventh.\n\nUltimately, the purpose of all music, including the General Bass, should be the glory of God and the refreshment of the spirit. If this goal is not upheld, the result is not true music but rather a devilish disturbance. Those who misuse this noble and divine gift for arousing undesirable passions can expect no good for their souls." },
    { id: 3, title: 'Chapter III: On the Claves Signatae that Appear in the General Bass', "content": "The chapter covers the various clef symbols used in the general bass:\n\n*   The French violin clef, drawn on the bottom line, indicating f\n*   The German violin clef, drawn on the second line, also indicating f\n*   The discant or cantus clef, a common symbol for singers, placed on the bottom line, indicating c\n*   The high alto or low discant clef, commonly used by the French, drawn like the previous but on the second line, also indicating c\n*   The normal bass clef, placed on the middle line, indicating c\n*   The tenor clef, on the fourth line, indicating c\n*   The common bass clef, indicated with a symbol on the fourth line, representing the unstriped f\n*   The high bass clef, also frequently used by the French, is the unstriped f on the middle line\n*   The low bass clef is the unstriped c on the top line\n\nThough the author does not approve of learning to play from German tablature, he finds one advantage in it - the ability to conveniently name the notes or tones as they follow each other on the keyboard, such as the great c, the one-line c, the two-line f, the unstriped d, etc. The author observes this convention in the treatise.\n\nThe high bass on the middle line is commonly called baritone. All other high clefs appearing in the general bass are called bassetgens." },
    { id: 4, title: 'Chapter IV: On the Tact or Mensur', "content": "The chapter presumes that the learner who wishes to grasp the general bass in a short time not only knows the notes beforehand, but also understands the intervals to some extent (either through previous practice in singing or other instruction in music) and knows the difference between the tacts.\n\nThe chapter notes that in contemporary times, a simple tact is notated in two ways:\n\n*   The first is used by the Italians and Germans mostly in sacred church music, and leads to a slow, stately tact. If it should go fast, the composer expressly writes allegro or presto underneath. For a slow tempo, adagio or lento is written. If the piece is to be played freshly and strongly, forte is written underneath. If it is to be played weakly and gently, piano is indicated.\n*   The second way is used by the French in pieces that should be played fresh and fast.\n\nIn other matters, a good master already knows in advance which pieces require a slow or fast tact. For example:\n\n*   In a French sonata, the simple tact in the overture is played slowly, in the entree and gavotte somewhat faster, in the ballo even more briskly, and in the bouree the fastest. The aria, however, is quite slow.\n*   In triple time, a minuet has a fast tempo, while the sarabande must go gravely and slowly. The gigue usually comes last, somewhat fresh and fast." },
    { id: 5, title: 'Chapter V: On the Triade Harmonica', "content": "The trias harmonica properly has its use in composition, but since the general bass is a beginning to composing, its mention is appropriate here due to the concordance of consonances and dissonances, which the person playing the general bass makes. The author is certain that if a learner takes this to heart, he will have already grasped a great part of the whole art.\n\nThe trias harmonica is defined as a coupling of the third and fifth when added to a fundamental note, which occurs through all tones. The trias is also called:\n\n*   Radix omnis harmoniae, because all harmony with its measure springs from it as from a root\n*   Radix unitrisona, because there are three tones which yet make only one complete harmony, so that one in three and three in one are wonderfully joined together\n\nTheologians therefore like to use this trias harmonica or radix unitrisona in their sermons and writings to show and explain to some extent the great mystery of the three self-existent persons in the one divine being.\n\nFurthermore, this trias harmonica or radix harmoniae is either:\n\n*   Radix simplex: The simple and properly so-called trias consists of only three tones\n*   Radix aucta: The augmented trias has the octave as a companion to vary the harmony and make it more complete\n*   Radix diffusa: The scattered trias is dispersed into different octaves. The following rule is to be observed: the closer the tones are to each other, the better the symphony.\n\nSeveral musical examples are provided to illustrate these concepts.\n\nThe chapter notes that more could be said, but whoever has the desire to learn will easily grasp the rest from practice." },
    { id: 6, title: 'Chapter VI: Some General Rules to Observe When Playing the General Bass', "content": "The chapter lays out several rules for playing the general bass:\n\n1.  Play the prescribed general bass with the left hand alone; play the other voices (whether indicated by numbers or not) with the right hand.\n2.  With the right hand, generally stay below the two-line f in the octave, or at most up to the two-line g, and no lower than the unstriped a.\n3.  Thirds and sixths may be played ascending and descending as much as desired, unless prevented by a superscribed number.\n4.  The third is played with all numbers, unless prevented by the second and fourth.\n5.  Two fifths and two octaves must not follow each other, as this is a mistake and sounds bad. To avoid this, there is an old rule that the hands must always go against each other, so that when the left ascends, the right descends, and vice versa.\n6.  The best advantage to avoid two fifths and two octaves is to take the sixth to help and alternate with it.\n7.  When the bass or bass theme goes high, one must follow with the right hand higher, and the consonances and dissonances are then better placed in the same octave in which the bass theme stands.\n8.  If the singer or instrumentalist sings or plays the numbers set above the general bass, the organist need not necessarily take them, but can simply strike the thirds as they fit. If he wants to do something more artificial, it is left to his own discretion.\n9.  Numbers standing above each other are struck together; what stands after each other is played in proper order." },
    { id: 7, title: 'Chapter VII: How to Play a Simple General Bass that Requires No Numbers', "content": "If nothing is marked above the general bass, one plays nothing more than the consonances (the third, fifth and octave). The first rule of the previous chapter should be observed - that the right hand not go higher than the two-lined c or f, nor lower than the unstriped a or g.\n\nA simple bass example is provided. The right hand plays the consonances in the discant as follows:\n\n*One is not bound to always play in the same way, but can make the lowest voice (which would be the tenor in four-part writing) the highest or the discant.*\n\n*These unison notes can also be varied (which can be practiced in all general basses, including chorales). The consonances in the discant (which would otherwise be struck simultaneously with the right hand) are divided and played from bottom to top in succession, making two quarters or four eighth notes out of one half note. This type is almost like bowing.*\n\n*The bass can also be varied in the same way.*\n\nSeveral musical examples are given to illustrate these points. The author encourages the learner to diligently observe and investigate how the trias harmonica is found in each note in all tones. By practicing this, one will soon become adept at playing the general bass and lay a good foundation to make everything one desires from any bass, such as preludes, fugues, ciacconas, allemandes, courantes, sarabandes, gigues, and even minuets.\n\nTo show how fifths and octaves do not fit together but sound bad when two or more follow each other (even though they are perfect consonances), an example is provided for instruction." },
    { id: 8, title: 'Chapter VIII: On the Best General Bass', "content": "The chapter provides several rules for playing a more advanced general bass:\n\n*   When an x stands before a note, the sixth and third are always played on that note, even if the sixth is not expressly written above it.\n*   Where a sixth stands above the note, one takes nothing more than the sixth; the octave must be omitted both in writing and in playing.\n*   The sign * indicates a major third. When an * stands above an A, one must play the major third c-sharp. Without it, one would take the unstriped c. The sign b, when standing above a note, means that the minor third must be played, namely the unstriped d-sharp. These signs must all be well observed.\n*   Everything marked with * is called major, such as 2*, 4*, 6*. What is marked with b is called minor, such as 2b, 4b, 5b, 6b, or 7b.\n*   When B follows A, F follows E, c follows H, and vice versa (A follows B, E follows F, H follows c), the sixth is always played on A, H and E, whether it is written above or not.\n*   It is important to note that on the last note at the end, one must always play the major third, even if the entire preceding melody was in minor.\n*   Though the author knows that French composers do the opposite, not everything is good just because it is from France or bears that name. Otherwise the hideous disease called \"the French\" would also be half in esteem.\n*   Where cadence clauses lie, such as 3443 or 44, they are called syncopations because they bind and entangle together. Sometimes these are also set singly, such as 765 or 3443/44, whereby it is nevertheless fully felt as if they stood there expressly as said before. In whole notes, the cadences are also set thus: 34342343 44*, then nothing follows until the end.\n\nSeveral short musical examples are provided to illustrate these points." },
    { id: 9, title: 'Chapter IX: Some Notes on the Seventh, Ninth, Eleventh, and Other Following Numbers', "content": "The chapter provides several rules and observations regarding the use of sevenths, ninths, elevenths, and other intervals in the general bass:\n\n*   Where the seventh stands, it must always lie in the fingers beforehand, and only the third is struck in addition. Where it fits, the fifth can also be taken.\n*   Where seventh and ninth stand above each other, the seventh must lie down first, the ninth and third are struck afterwards.\n*   Where seventh, fifth and third stand above each other, nothing lies down, but it is an ordinary stroke.\n*   When seventh, fifth, fourth and third stand thus above and next to each other, the fourth alone must lie down as mentioned. The seventh and fifth are struck simultaneously, while the fourth lies in the meantime, which then immediately resolves upward into the third.\n*   Or also when ninth, seventh, octave and sixth are seen thus, 7 lies down first and 6 is struck afterwards.\n*   Sometimes these numbers also appear: eleventh, ninth, tenth and octave above each other. Then the 9 lies down first, and the 8 is struck afterwards.\n\nThe chapter concludes by noting that when seventh and fifth stand above each other, such a grip or such numbers must already lie down beforehand. Several short musical examples are provided to illustrate these concepts." },
    { id: 10, title: 'Chapter X: On the Passages and Fugues in the General Bass', "content": "The bass is not always the fundamental voice in the two lowest octaves; rather, the bass can also be led higher, sometimes in the discant, alto, and tenor. Such a high fundamental voice must then be called a bassist or bass-bow.\n\nWhen fugues are set in the general bass, it should be noted that a fugue consists of no more than two voices, yet can be set or composed with three, four, ten to twenty voices. In a fugue, sometimes one hand, sometimes the other begins alone first. The other hand repeats or echoes. This is often shown in copper engravings of keyboard tablature, so that one can easily see how it should be played.\n\nThe chapter provides a musical example of a fugue passage written in standard notation.\n\nGod willing, proper instruction shall be given to the general music-friend in the other parts on how to treat the fugues ex tempore. For now, the focus has been solely on the general bass. The whole art of playing it lies in the few rules and observations above." },
    { id: 11, title: 'Chapter XI: How to Pass from One Tone to Another', "content": "When an organist is preluding, it often happens that they unintentionally fall into a foreign tone that does not match the piece they are about to play or sing. This should never happen, as it violates the listeners' ears.\n\nThe author provides examples of how to properly transition between tones:\n\n*   When the song is in a major key, take the B and replace it with an H using the same numbers. This will show which tones fit and can be reached with reputation.\n*   To get even closer, play the Sexta of the tone you want to jump to, from which all tones will stand that fit with it, and use the Quarta to cadence in that tone.\n*   Another way is shown to transition through all the tones across the entire keyboard.\n\nThis is all useful when a piece has finished and you need to smoothly begin another in a completely different key. The author notes that it sounds terrible when the transition is jarring, comparing it to a \"fist to the eye\".\n\nBy following these methods, the organist can avoid unpleasant tonal clashes and skillfully navigate between pieces in different keys during a performance." },
    { id: 12, title: 'Chapter XII: Advice for Dealing with Incorrect Notation in Figured Bass', "content": "When a piece is incorrectly transcribed through repeated copying, or the sharps, flats, numbers and symbols are omitted, it can be difficult for an organist to determine if it should be in a major or minor key. Playing it wrong can be very grating to the listeners' ears.\n\nThe author provides guidance on what the ambitus or range can stand for in each key:\n\n*   For C major/minor, F, G, A and C minor are set but not prefixed\n*   For C major, nothing is prefixed\n*   For D minor, there must mostly be a flat on the B line\n*   For D major, there must be double sharps before C and F\n*   And so on for the other keys...\n\nThe author notes that the third of the fundamental tone shows whether the melody is major or minor.\n\nIn the conclusion, the author expresses confidence that anyone who applies themselves diligently to the provided examples and exercises will master figured bass well enough to play along with other musicians without shame. He acknowledges there are many excellent masters who could have written a better treatise on the topic, but to his knowledge none have done so in a thorough way accessible to beginners.\n\nThe author invites feedback and correction of any mistakes, and asks the reader to look forward to future installments covering other aspects of organ playing, such as:\n\n*   Playing chorales\n*   Improvising preludes\n*   Writing fugues\n*   Maintaining an organ\n\nHe closes by wishing the reader farewell." }
  ];

  const markdownComponents = {
    p: ({ node, children, ...props }) => <p className="my-4" {...props}>{children}</p>,
    li: ({ node, children, ...props }) => <li className="my-2 ml-8 pl-4 list-disc" {...props}>{children}</li>,
    h1: ({ node, children, ...props }) => <h1 className="my-4 text-4xl font-bold text-indigo-600" {...props}>{children}</h1>,
    h2: ({ node, children, ...props }) => <h2 className="my-3 text-2xl font-semibold" {...props}>{children}</h2>,
    // Ensure that strong tags do not mistakenly wrap block-level elements like h1, h2
    strong: ({ node, children, ...props }) => <strong className="my-3 text-xl text-indigo-600" {...props}>{children}</strong>
  };


  return (
    <div className="container mx-auto px-4 py-8">
      {chapters.map(chapter => (
        <div key={chapter.id} className="max-w-2xl mx-auto mt-4">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <button
              onClick={() => setOpenChapter(chapter.id === openChapter ? null : chapter.id)}
              className="w-full flex justify-between items-center text-left text-2xl font-semibold text-gray-900 pr-8"
            >
              {chapter.title}
              <svg
                className="ml-4 w-6 h-6 flex-shrink-0 transform transition duration-300 rotate-0"
                style={{ transform: openChapter === chapter.id ? 'rotate(180deg)' : 'rotate(0deg)' }}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            <div
              className={`text-gray-700 mt-2 overflow-hidden transition-all duration-300 ${openChapter === chapter.id ? 'max-h-full' : 'max-h-0'
                }`}
            >
              <ReactMarkdown components={markdownComponents}>{chapter.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NiedtChapterCards;