import ReactMarkdown from 'react-markdown';

const DeLaraAudio = () => {

  const summary = "## Summary of Adelina de Lara's Interview\n\n**Clara Schumann's Teaching and Legacy:**\n\n* Adelina de Lara studied under Clara Schumann for six years, following the Schumann tradition passed down from Bach, Beethoven, and other classical masters.\n* Clara emphasized tone quality, rhythm, phrasing, sincere interpretation, and no affectation.\n* She disliked students using the soft pedal and encouraged a genuine pianissimo touch.\n* Proper fingering was crucial, with each note assigned a specific finger and minimal assistance from the other hand.\n* Rhythm was paramount, emphasizing the conductor's beat and even accenting trills.\n* Clara rarely raised her voice but expressed disapproval through fidgeting, while approval was shown with a smile and a pat on the shoulder. \n\n**Adelina's Musical Education:**\n\n* She had two one-hour lessons per week, starting with Bach and other early masters, then sonatas, concertos, and lighter music.\n* Sight-reading, opera attendance, and symphony rehearsals were also part of the rigorous curriculum.\n* Clara did not focus on scales or technique, leaving those to her daughters' instruction.\n* Lessons were held at the Schumann house with other students observing.\n\n**Adelina's Fond Memories:**\n\n* She recalls Brahms' kindness and humor during his visits to the Schumann household.\n* She cherishes the memory of playing for Brahms and Clara, particularly Brahms' own compositions.\n* The Gesellshaft parties at Brahms' home, where students performed, remain unforgettable.\n* Adelina expresses deep gratitude for the happiest and most peaceful years of her life spent studying in Frankfurt under Clara Schumann."

  const markdownComponents = {
    p: ({ node, children, ...props }) => <p className="my-4" {...props}>{children}</p>,
    li: ({ node, children, ...props }) => <li className="my-2 ml-8 pl-4 list-disc" {...props}>{children}</li>,
    h1: ({ node, children, ...props }) => <h1 className="my-4 text-4xl font-bold text-indigo-600" {...props}>{children}</h1>,
    h2: ({ node, children, ...props }) => <h2 className="my-3 text-2xl font-semibold" {...props}>{children}</h2>,
    strong: ({ node, children, ...props }) => <strong className="text-indigo-600" {...props}>{children}</strong>
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto">
        <div className="mb-8">
          <div className="w-full md:max-w-[560px] mx-auto">
            <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://www.youtube.com/embed/j0H0P6094-8?si=nrwb5QuwJIN56s7Q"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                className="absolute top-0 left-0 w-full h-full shadow-xl rounded-xl"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="mb-8">
          <div className="text-gray-700 leading-relaxed">
            <ReactMarkdown components={markdownComponents}>{summary}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeLaraAudio;
