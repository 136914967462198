import React from 'react';
import { Routes, Route, NavLink, Navigate } from 'react-router-dom';
import Transcription from './CPETranscription';
import Conversation from './CPEConversation';
import Quiz from '../Quiz';
import TopicCloud from './CPETopicCloud';

const CPEContainer = () => {
  return (
    <>
      <div className="text-center mb-10">
        <h1 className="text-4xl font-bold text-indigo-600 mb-4">Carl Philipp Emanuel Bach</h1>
        <h2 className="text-2xl text-indigo-500 mb-8">Versuch über die wahre Art das Clavier zu spielen</h2>
        {/* Sub-Navigation for CPE Section */}
        <div className="flex flex-col md:flex-row justify-center">
        <NavLink
          to="transcription"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Complex Transcriptions
        </NavLink>
        <NavLink
          to="conversation"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Conversation
        </NavLink>
        <NavLink
          to="quiz"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Quiz
        </NavLink>
        <NavLink
          to="explore-topics"
          className={({ isActive }) =>
            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
        >
          Explore Topics
        </NavLink>
        </div>
      </div>
      <Routes>
        <Route path="transcription" element={<Transcription />} />
        <Route path="conversation" element={<Conversation />} />
        <Route path="quiz" element={<Quiz />} />
        <Route path="explore-topics" element={<TopicCloud />} />
        {/* Redirect to "transcription" as the default sub-route within CPE */}
        <Route index element={<Navigate replace to="transcription" />} />
      </Routes>
    </>
  );
};

export default CPEContainer;
