import ReactMarkdown from 'react-markdown';

const PITAORThesis = () => {

  const ideas=`# Folk-Music in Art-Music - Henry F. Gilbert 
   *10 potential Master's thesis ideas and directions, along with focused questions suitable for a music university student:*

  1. **The influence of Croatian folk music on Joseph Haydn's compositions**
     - How did Haydn's exposure to Croatian folk music shape his compositional style and techniques?
     - Which specific Croatian folk tunes can be identified in Haydn's works, and how were they incorporated?
  
  2. **The role of folk music in the development of national identity in 19th-century Russia**
     - How did the use of Russian folk music by composers like Glinka, Tchaikovsky, and Rimsky-Korsakov contribute to the formation of a distinct Russian musical identity?
     - What social, political, and cultural factors influenced the incorporation of folk elements in Russian art music?
  
  3. **The impact of Frédéric Chopin's use of Polish folk music on the development of nationalism in music**
     - How did Chopin's incorporation of Polish folk music elements in his compositions inspire other composers to explore their own national musical heritage?
     - In what ways did Chopin's approach to folk music differ from that of his contemporaries, and how did this influence the perception of his music as uniquely Polish?
  
  4. **The relationship between folk music and art music in the works of Antonín Dvořák**
     - How did Dvořák's deep understanding and appreciation of Czech folk music shape his compositional style and techniques?
     - In what ways did Dvořák's use of folk music elements evolve throughout his career, and how did this reflect his changing views on the role of folk music in art music?
  
  5. **The significance of Felipe Pedrell's use of Spanish folk music in the development of a national musical identity**
     - How did Pedrell's incorporation of Catalonian and Mauresque folk music elements in his compositions contribute to the formation of a distinctly Spanish musical identity?
     - What were the philosophical and ideological underpinnings of Pedrell's approach to folk music, and how did these influence his compositional choices?
  
  6. **The role of folk music in the development of Hungarian national identity in the works of Franz Liszt**
     - How did Liszt's incorporation of Hungarian and Gypsy folk music elements in his compositions contribute to the formation of a distinct Hungarian musical identity?
     - In what ways did Liszt's approach to folk music differ from that of his contemporaries, and how did this influence the perception of his music as uniquely Hungarian?
  
  7. **The influence of folk music on the development of musical modernism in the works of Igor Stravinsky**
     - How did Stravinsky's use of Russian folk music elements in ballets like "The Firebird" and "Petrouchka" contribute to the development of musical modernism?
     - In what ways did Stravinsky's approach to folk music differ from that of his predecessors, and how did this reflect his avant-garde artistic vision?
  
  8. **The relationship between folk music and art music in the works of Béla Bartók**
     - How did Bartók's extensive research and collection of Hungarian and other Eastern European folk music inform his compositional style and techniques?
     - In what ways did Bartók's use of folk music elements evolve throughout his career, and how did this reflect his changing views on the role of folk music in art music?
  
  9. **The impact of African-American folk music on the development of American classical music**
     - How did the incorporation of African-American folk music elements, such as spirituals, blues, and jazz, shape the development of a distinctly American classical music tradition?
     - Which composers were most influential in this process, and how did their approaches to folk music differ from one another?
  
  10. **The future of folk music in American art music: challenges and opportunities**
      - What role might the diverse folk music traditions of immigrant communities play in shaping the future of American art music?
      - How can American composers effectively incorporate folk music elements while avoiding cultural appropriation and maintaining artistic integrity?`

  const markdownComponents = {
    p: ({ node, children, ...props }) => <p className="my-4" {...props}>{children}</p>,
    li: ({ node, children, ...props }) => <li className="my-2 ml-8 pl-4 list-disc" {...props}>{children}</li>,
    h1: ({ node, children, ...props }) => <h1 className="my-4 text-4xl font-bold text-indigo-600" {...props}>{children}</h1>,
    h2: ({ node, children, ...props }) => <h2 className="my-3 text-2xl font-semibold" {...props}>{children}</h2>,
    strong: ({ node, children, ...props }) => <strong className="" {...props}>{children}</strong>
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto">
        <div className="mb-8">
          <div className="text-gray-700 leading-relaxed">
            <ReactMarkdown components={markdownComponents}>{ideas}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PITAORThesis;
