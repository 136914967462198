import React from 'react';
import { BrowserRouter, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import CPEContainer from './CPE/CPEContainer'; // Container for CPE-related routes
import SchumannContainer from './Schumann/SchumannContainer'; // Container for Schumann-related routes (you'll need to create this)
import Transcription from './CPE/CPETranscription';
import Conversation from './CPE/CPEConversation';
import Quiz from './Quiz';
import TopicCloud from './CPE/CPETopicCloud';
import SchumannLetters from './Schumann/SchumannLetters';
import SorgeSummary from './SorgeAndNiedt/SorgeSummary';
import SorgeAndNiedtContainer from './SorgeAndNiedt/SorgeAndNiedtContainer';
import NiedtSummary from './SorgeAndNiedt/NiedtSummary';
import SorgeNiedtDifference from './SorgeAndNiedt/SorgeNiedtDifference';
import SorgeNiedtSimilarities from './SorgeAndNiedt/SorgeNiedtSimilarities';
import SchumannDepression from './Schumann/SchumannDepression';
import SchumannHappiness from './Schumann/SchumannHappiness';
import SchumannMusicalWorks from './Schumann/SchumannMusicalWorks';
import SorgeChapterCards from './SorgeAndNiedt/SorgeChapterCards';
import NiedtChapterCards from './SorgeAndNiedt/NiedtChapterCards';
import NiedtStudyProgram from './SorgeAndNiedt/NiedtStudyProgram';
import AudioContainer from './Audio/AudioContainer';
import DeLaraAudio from './Audio/DeLaraAudio';
import EibenshuetzAudio from './Audio/EibenschuetzAudio';
import BernsteinAudio from './Audio/BernsteinAudio';
import FolkThesis from './Thesis/FolkThesis';
import PITAORThesis from './Thesis/PITAORThesis';
import ThesisContainer from './Thesis/ThesisContainer';


const App = () => {
  return (
    <BrowserRouter>
      <div className="bg-gray-100 font-sans">
        <div className="container mx-auto px-4 py-8">
          <div className="flex items-center justify-center mb-10">
            <div className="flex items-center space-x-3">
              <img className="max-h-28" src={`${process.env.PUBLIC_URL}/bookworm-upscaled.png`} alt="Logo" />
              <h1 className="font-logo text-3xl bg-gradient-to-r from-indigo-500 via-orange-500 to-rose-500 text-transparent bg-clip-text animate-gradient-x">                Bookworm
              </h1>
            </div>
          </div>
          <div className="text-center mb-10">
            {/* Navigation Buttons for Top-Level Sections */}
            <NavLink
              to="/cpe"
              className={({ isActive }) =>
                `mx-2 mb-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white text-xl font-bold py-4 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
            >
              C.P.E Bach
            </NavLink>
            <NavLink
              to="/schumann"
              className={({ isActive }) =>
                `mx-2 mb-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white text-xl font-bold py-4 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
            >
              Schumann
            </NavLink>
            <NavLink
              to="/sorge-and-niedt"
              className={({ isActive }) =>
                `mx-2 mb-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white text-xl font-bold py-4 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""
                }`
              }
            >
              Sorge & Niedt
            </NavLink>
            <NavLink
              to="/audio"
              className={({ isActive }) =>
                `mx-2 mb-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white text-xl font-bold py-4 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""
                }`
              }
            >
              Audio Analysis
            </NavLink>
            <NavLink
              to="/thesis-brainstorm"
              className={({ isActive }) =>
                `mx-2 mb-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white text-xl font-bold py-4 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""
                }`
              }
            >
              Thesis Brainstorm
            </NavLink>
          </div>

          <Routes>
            <Route path="/" element={<Navigate replace to="/cpe/transcription" />} />
            <Route path="/cpe" element={<CPEContainer />}>
              {/* These are now nested routes under /cpe */}
              <Route path="conversation" element={<Conversation />} />
              <Route path="transcription" element={<Transcription />} />
              <Route path="quiz" element={<Quiz />} />
              <Route path="explore-topics" element={<TopicCloud />} />
              {/* Redirect from /cpe to /cpe/transcription */}
              <Route index element={<Navigate replace to="transcription" />} />
            </Route>
            <Route path="/schumann" element={<SchumannContainer />}>
              <Route path="letters" element={<SchumannLetters />} />
              <Route path="depression" element={<SchumannDepression />} />
              <Route path="happiness" element={<SchumannHappiness />} />
              <Route path="works" element={<SchumannMusicalWorks />} />
            </Route>
            <Route path="/sorge-and-niedt" element={<SorgeAndNiedtContainer />}>
              <Route path="sorge-summary" element={<SorgeSummary />} />
              <Route path="sorge-chapters" element={<SorgeChapterCards />} />
              <Route path="niedt-summary" element={<NiedtSummary />} />
              <Route path="niedt-chapters" element={<NiedtChapterCards />} />
              <Route path="niedt-study-program" element={<NiedtStudyProgram />} />
              <Route path="differences" element={<SorgeNiedtDifference />} />
              <Route path="similarities" element={<SorgeNiedtSimilarities />} />
            </Route>
            <Route path="/audio" element={<AudioContainer />}>
              <Route path="de-lara" element={<DeLaraAudio />} />
              <Route path="eibenshuetz" element={<EibenshuetzAudio />} />
              <Route path="bernstein" element={<BernsteinAudio />} />
            </Route>
            <Route path="/thesis-brainstorm" element={<ThesisContainer />}>
              <Route path="pitaor" element={<PITAORThesis />} />
              <Route path="folk" element={<FolkThesis />} />
            </Route>
            {/* Redirect any unknown routes to the default route */}
            <Route path="*" element={<Navigate replace to="/cpe/transcription" />} />
          </Routes>

        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;