import React, { useState } from 'react';

function Quiz() {
  const [revealed, setRevealed] = useState({});

  const questions = [
    {
      page: 1,
      question: "What makes the clavier a unique instrument compared to others?",
      answer: "The clavier uniquely combines individual qualities of various instruments, offering the ability to produce complete harmonies that typically require multiple instruments, highlighting its exceptional versatility and complexity."
    },
    {
      page: 1,
      question: "Why are the demands on clavier players considered exceptionally high?",
      answer: "Clavier players face high demands due to the instrument's complexity, requiring mastery over a wide range of skills including the ability to improvise, play in all keys with ease, and perform intricate compositions, reflecting the clavier's comprehensive musical capabilities."
    },
    {
      page: 2,
      question: "How does improvisation play a role in a clavier player's skill set?",
      answer: "Improvisation is crucial for clavier players, demanding the spontaneous creation of complex music that adheres to strict rules of harmony and melody, showcasing their deep musical understanding and creativity."
    },
    {
      page: 3,
      question: "What challenges do clavier players face when dealing with unfamiliar instruments?",
      answer: "Clavier players often must adapt to unfamiliar instruments, which may vary in condition and tuning, requiring flexibility and a keen ear to ensure performances maintain their intended quality and expression."
    },
    {
      page: 4,
      question: "How did the clavier influence musical pedagogy and performance in history?",
      answer: "The clavier significantly influenced musical pedagogy and performance, with its players and composers developing techniques and compositions that pushed the boundaries of music, enriching the instrument's legacy and shaping the course of Western music."
    },
    {
      page: 5,
      question: "In what way does the author propose correcting misguided musical instruction?",
      answer: "The author advocates for a return to the true principles of musical art, emphasizing the importance of a solid foundation in clavier technique and theory to rectify the effects of poor instruction and elevate the standards of musical education."
    },
  ];

  const toggleAnswer = (index) => {
    setRevealed(prev => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="space-y-12 p-4">
      {questions.map((q, index) => (
        <div key={index} className="p-4 shadow rounded-lg bg-white max-w-3xl mx-auto">
          <h2 className="text-2xl text-indigo-500">Page {q.page}</h2>
          <div className="font-semibold text-lg">{q.question}</div>
          <div className={`mt-4 ${revealed[index] ? '' : 'blur-sm'}`}>
            {q.answer}
          </div>
          <button
            onClick={() => toggleAnswer(index)}
            className="mx-2 mt-6 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl transition duration-300 ease-in-out"
          >
            {revealed[index] ? 'Hide Answer' : 'Show Answer'}
          </button>
        </div>
      ))}
    </div>
  );
}

export default Quiz;
