import React, { useRef } from 'react';

const Conversation = () => {
    const messagesEndRef = useRef(null);

    const conversation = [
        {
            text: "What are the rules about the length of the appoggiatura?",
            sender: "user"
        },
        {
            text: "According to the excerpts provided from C.P.E. Bach's textbook, the main rules about the length of the appoggiatura are:\n\nOn pages 87-88, it states the usual rule is that appoggiaturas take half the value from a following note of duple length (e.g. a quarter note before a half note), and two-thirds the value from a note of triple length. Some examples are provided in Figures 73 and 74.\n\nPage 88 mentions that appoggiaturas which depart from this rule of duration should be written out as regular large notes to avoid confusion.\n\nThe excerpts on pages 90-92 provide some additional details and examples related to the length of appoggiaturas before various rhythmic values like dotted notes, triplets, and thirty-second notes. In general, the appoggiatura should be played quickly in these contexts so as not to obscure the rhythm.\n\nSo in summary, the standard rule is for the appoggiatura to take either 1/2 or 2/3 of the following note's value depending on if that note is duple or triple in length, with exceptions notated explicitly. The appoggiatura should be played quickly in faster rhythmic figures to maintain rhythmic clarity.",
            sender: "ai"
        },
        {
            text: "Why are appoggiaturas notated with various note values (sometimes as half notes, quarter notes, eight notes, etc.)?",
            sender: "user"
        },
        {
            text: "The excerpts provide a few reasons why appoggiaturas are notated with various note values:\n\nOn page 87, it states that in the past all appoggiaturas were written as eighths, but \"Today, we could not do without the notation of their real values, for the rules covering their length in performance are insufficient to cover all cases, since all types appear before every kind of note.\"\n\nPage 88 elaborates that appoggiaturas which depart from the standard rule of taking 1/2 or 2/3 of the next note's value \"should be written as large notes.\"\n\nThen on page 96, Bach recommends that \"it is better to write the appoggiatura in such cases as a large note with its length clearly notated\" when the appoggiatura itself is meant to be decorated with another ornament.\n\nSo in summary, using different note values allows the composer to clearly specify the intended length of the appoggiatura, especially in cases that deviate from the standard rules. This is important since appoggiaturas can appear before many different note values. Notating the \"real values\" removes ambiguity and makes the ornament's length readily apparent to the performer.",
            sender: "ai"
        },
        {
            text: "How about appoggiaturas that are written as small notes/grace notes?",
            sender: "user"
        },
        {
            text: "Regarding appoggiaturas written as small notes or grace notes, the excerpts provide the following information:\n\nOn page 87, it mentions that appoggiaturas sometimes appear in small notation, and in those cases \"the large notes before which they stand retain their length visually although in performance they always lose some of it to the ornament.\"\n\nPage 90 states that the \"unvariable short appoggiatura\" is notated with \"one, two, three, or more tails\" (referring to the flags on the stem of the small note). This type \"is played so rapidly that the following note loses scarcely any of its length.\" Several examples are given in Figure 76.\n\nThe excerpt on page 98 cautions against the \"unaccented appoggiatura\" which is the result of the small grace note \"usurping a portion of the preceding note's value\" rather than taking time from the following main note as it should.\n\nIn summary, when written as small grace notes, appoggiaturas are meant to be played quickly, taking only a small amount of time from the following main note. The number of \"tails\" on the small note itself indicates its speed. However, the grace note should not be played before the beat such that it takes time from the previous note - this is considered an error.",
            sender: "ai"
        },
        {
            text: "Do appoggiaturas always start on the beat?",
            sender: "user"
        },
        {
            text: "Yes, according to the information provided in the excerpts, appoggiaturas should always start on the beat of their main note.\n\nOn page 87, it states clearly that \"appoggiaturas are louder than the following tone, including any additional embellishment, and that they are joined to it in the absence as well as the presence of a slur. Both of these points are in accord with the purpose of appoggiaturas, which is to connect notes. They must be held until released by the following tone so that both are smoothly joined.\"\n\nThe excerpt on page 98 further reinforces this by cautioning against the \"repulsive unaccented appoggiatura\" which results from \"separating an appoggiatura from its following tone either because the ornament is prematurely quitted or because it has usurped a portion of the preceding note's value.\" It states that \"the remedy for unaccented appoggiaturas is to shift them ahead to the next accent.\"\n\nTherefore, based on the rules and explanations given by C.P.E. Bach, appoggiaturas are meant to fall directly on the beat of the principal note they ornament, taking time from that note rather than from the previous note. Playing an appoggiatura before the beat is considered incorrect.",
            sender: "ai"
        },
        {
            text: "Can you elaborate about the \"repulsive unaccented appoggiatura\"?",
            sender: "user"
        },
        {
            text: "Certainly! The \"repulsive unaccented appoggiatura\" is discussed in more detail on pages 98-100 of the excerpts.\n\nAccording to Bach, this incorrect type of appoggiatura results from one of two errors:\n\n1. The appoggiatura is \"prematurely quitted\" - meaning it is played too short and released before the beat of the main note it should ornament.\n2. The appoggiatura \"usurped a portion of the preceding note's value\" - meaning it is played before the beat, taking time from the previous note rather than the note it should decorate.\n\nBach states on page 98 that this type of misplaced appoggiatura is \"so extraordinarily popular\" but is \"reserved, unfortunately, for the most legato passages\" such as the example given in Figure 88b. He considers this a \"dislocation\" of the appoggiatura.\n\nThe \"remedy\" Bach proposes on page 100 is \"to shift them ahead to the next accent\" - in other words, to place the appoggiatura directly on the beat of its main note where it belongs.\n\nHe provides a few examples in Figures 88 and 89 of how an unaccented appoggiatura might be used in a \"more tolerable\" way, but still views them as inferior to a properly placed appoggiatura.\n\nOverall, Bach clearly views the \"unaccented appoggiatura\" as a common error to be avoided, one that disrupts the proper flow and function of the ornament. The appoggiatura, in his view, must fall on the beat to fulfill its role of connecting and enlivening the melody.",
            sender: "ai"
        }
    ]

    const formatMessage = (text) => {
        return text.split('\n').map((line, i) => (
            <React.Fragment key={i}>
                {line + "\n"}
            </React.Fragment>
        ));
    };

    return (
        <div className="flex flex-col max-w-screen-lg mx-auto">
            <div className="flex-grow overflow-y-auto p-8 space-y-8 rounded-2xl bg-white shadow-xl">
                {conversation.map((message, index) => (
                    <div
                        key={index}
                        className={`flex ${message.sender === 'ai' ? 'justify-start' : 'justify-end'
                            }`}
                    >
                        <div
                            className={`rounded-2xl shadow-lg px-4 py-3 ${message.sender === 'ai'
                                    ? 'bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
                                    : 'bg-gray-200 text-gray-800'
                                } max-w-[70%] whitespace-pre-wrap`}
                        >
                            {formatMessage(message.text)}
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
        </div>
    );
};

export default Conversation;