import React from 'react';
import { Routes, Route, Navigate, NavLink } from 'react-router-dom';
import SorgeSummary from './SorgeSummary';
import NiedtSummary from './NiedtSummary';
import SorgeNiedtDifference from './SorgeNiedtDifference';
import SorgeNiedtSimilarities from './SorgeNiedtSimilarities';
import SorgeChapterCards from './SorgeChapterCards';
import NiedtChapterCards from './NiedtChapterCards';
import NiedtStudyProgram from './NiedtStudyProgram';

const SorgeAndNiedtContainer = () => {

    return (
        <>
            <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-indigo-600 mb-8">Georg Andreas Sorge & Friedrich Erhard Niedt</h1>
                {/* Sub-Navigation for CPE Section */}
                <div className="flex flex-wrap justify-center items-center gap-y-2 max-w-5xl mx-auto">
                    <NavLink
                        to="sorge-summary"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Sorge - Text Overview
                    </NavLink>
                    <NavLink
                        to="sorge-chapters"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Sorge - Chapters Summaries
                    </NavLink>
                    <NavLink
                        to="niedt-summary"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Niedt - Text Overview
                    </NavLink>
                    <NavLink
                        to="niedt-chapters"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Niedt - Chapters Summaries
                    </NavLink>
                    <NavLink
                        to="niedt-study-program"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Niedt - Study Program
                    </NavLink>
                    <NavLink
                        to="differences"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Differences
                    </NavLink>
                    <NavLink
                        to="similarities"
                        className={({ isActive }) =>
                            `mx-2 mb-2 inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-indigo-600 text-white font-bold py-2 px-6 rounded-lg shadow-lg transition-transform transform scale-100 hover:scale-105 ${isActive ? "ring ring-indigo-300" : ""}`}
                    >
                        Similarities
                    </NavLink>
                </div>
            </div>
            <Routes>
                <Route path="sorge-summary" element={<SorgeSummary />} />
                <Route path="sorge-chapters" element={<SorgeChapterCards />} />
                <Route path="niedt-summary" element={<NiedtSummary />} />
                <Route path="niedt-chapters" element={<NiedtChapterCards />} />
                <Route path="niedt-study-program" element={<NiedtStudyProgram />} />
                <Route path="differences" element={<SorgeNiedtDifference />} />
                <Route path="similarities" element={<SorgeNiedtSimilarities />} />
                <Route index element={<Navigate replace to="sorge-summary" />} />
            </Routes>
        </>
    );
};

export default SorgeAndNiedtContainer;
