import React, { useRef, useState, useEffect } from 'react';
import data from './cpeData.json'; // Ensure the path to your JSON is correct

const Transcription = () => {
    const totalPages = data.length;
    const itemRefs = useRef(data.map(() => React.createRef()));
    const [currentPage, setCurrentPage] = useState(0);
    const [isProgrammaticScroll, setIsProgrammaticScroll] = useState(false);

    const scrollToItem = (pageIndex) => {
        setIsProgrammaticScroll(true); // Indicate programmatic scrolling
        setCurrentPage(pageIndex);
        itemRefs.current[pageIndex]?.current?.scrollIntoView({
            block: 'start',
        });
        setTimeout(() => setIsProgrammaticScroll(false), 1000); // Reset after scrolling
    };

    const renderPaginationButtons = () => {
        const maxPageButtons = 3; // Maximum number of page buttons to display
        let startPage = Math.max(currentPage - 2, 0);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages - 1);
        
        // Adjust if near the end
        if (endPage === totalPages - 1) {
          startPage = Math.max(totalPages - maxPageButtons, 0);
        }
      
        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => scrollToItem(i)}
              className={`border px-2 py-1 md:px-3 text-xs md:text-sm font-semibold rounded-xl ${
                i === currentPage
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-blue-500 hover:animate-zoomInOut'
              }`}
            >
              {i + 1}
            </button>
          );
        }

        return (
            <div className="inline-flex items-center space-x-1 md:space-x-2 bg-indigo-200 bg-opacity-70 rounded-t-2xl p-2 md:p-4 shadow-xl">
            <button onClick={() => scrollToItem(0)} className="border px-3 py-1 text-sm font-semibold bg-white text-blue-500 rounded-xl hover:animate-zoomInOut flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5M11.25 19.5l-7.5-7.5 7.5-7.5" />
                    </svg>
                </button>
                <button onClick={() => scrollToItem(Math.max(currentPage - 1, 0))} className="border px-3 py-1 text-sm font-semibold bg-white text-blue-500 rounded-xl hover:animate-zoomInOut flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
                {pageNumbers}
                <button onClick={() => scrollToItem(Math.min(currentPage + 1, totalPages - 1))} className="border px-3 py-1 text-sm font-semibold bg-white text-blue-500 rounded-xl hover:animate-zoomInOut flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
                <button onClick={() => scrollToItem(totalPages - 1)} className="border px-3 py-1 text-sm font-semibold bg-white text-blue-500 rounded-xl hover:animate-zoomInOut flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 4.5l7.5 7.5-7.5 7.5m6-15l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>
        );
    };

    useEffect(() => {
        const handleScroll = () => {
            if (isProgrammaticScroll) return; // Ignore scroll events triggered programmatically

            const pageOffsets = itemRefs.current.map(ref => ref.current.offsetTop);
            const currentPageIndex = pageOffsets.findIndex((offset, index) => {
                const nextOffset = pageOffsets[index + 1] || Infinity;
                return window.scrollY >= offset && window.scrollY < nextOffset;
            });

            if (currentPageIndex !== -1 && currentPageIndex !== currentPage) {
                setCurrentPage(currentPageIndex);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPage, isProgrammaticScroll]); // Now also depends on isProgrammaticScroll


    return (
        <>
            <div>
                {data.map((item, index) => (
                    <div key={index} ref={itemRefs.current[index]} className="bg-white rounded-lg shadow-xl p-6 mb-8 flex flex-col xl:flex-row">
                        {/* Adjust the image container to appear on the left in a row layout on larger screens */}
                        <div className="xl:w-1/3 flex justify-center xl:justify-start mb-4 xl:mb-0 xl:mr-20">
                            <div className="w-full">
                                <img src={`${process.env.PUBLIC_URL}/${item.image}`} alt="Insight" className="w-full h-auto rounded-lg shadow-xl" />
                            </div>
                        </div>
                        {/* Text content container */}
                        <div className="xl:w-2/3">
                            <div className="leading-relaxed text-gray-700 grid grid-cols-1 xl:grid-cols-2 gap-8">
                                <div>
                                    <h2 className="text-2xl font-semibold mb-2 text-indigo-600">Transcription</h2>
                                    <h3 className="text-xl font-semibold mb-2 text-indigo-500">{item.transcription.title.de}</h3>
                                    <p>{item.transcription.de}</p>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-semibold mb-2 text-indigo-600">English Translation</h2>
                                    <h3 className="text-xl font-semibold mb-2 text-indigo-500">{item.transcription.title.en}</h3>
                                    <p>{item.transcription.en}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-8">
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Key Insights</h2>
                                    <ul className="space-y-4">
                                        {item.insights.map((insight, i) => (
                                            <li key={i} className="flex items-start">
                                                <svg className="w-6 h-6 text-green-500 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                                </svg>
                                                <span className="text-gray-700">{insight}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <h2 className="text-2xl font-semibold mb-4 text-indigo-600">Topics</h2>
                                    <div className="flex flex-wrap">
                                        {item.topics.map((topic, i) => (
                                            <span key={i} className="cursor-default inline-block bg-indigo-100 hover:bg-indigo-200 rounded-full px-3 py-1 text-sm font-semibold text-indigo-600 mr-2 mb-2">{topic}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="fixed inset-x-0 bottom-0">
                <div className="flex justify-center">
                    {renderPaginationButtons()}
                </div>
            </div>
        </>
    );
};

export default Transcription;