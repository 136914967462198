import ReactMarkdown from 'react-markdown';

const EibenshuetzAudio = () => {

  const summary = "## Summary of Ilona Eibenschütz's Interview \n\nThe interview recounts pianist Ilona Eibenschütz's musical journey and close relationship with Johannes Brahms. \n\n**Early Years and Brahms' Influence:**\n\n* Eibenschütz began studying with Clara Schumann at 13, which led to her meeting Brahms and becoming deeply impressed by his music. \n* She describes her excitement attending Brahms' premieres in Frankfurt and her growing admiration for his compositions. \n* Eibenschütz's early career in Vienna included significant performances and recognition from Brahms. \n\n**Ischl and Shared Musical Experiences:**\n\n* Both Brahms and Eibenschütz spent summers in Ischl, a haven for musicians.\n*  Eibenschütz describes Brahms' enjoyment of their casual dinners and the vibrant musical atmosphere in Ischl with renowned artists like the Kneisel Quartet and Johann Strauss.\n*  She shares anecdotes of performances for Brahms, including his own clarinet sonatas and the memorable rendition of his clarinet quintet by Richard Mühlfeld.\n\n**A Special Bond and Premieres:**\n\n* Eibenschütz highlights Brahms' trust in her by playing his newly composed Klavierstücke for her before anyone else.\n* She expresses her immense joy and honor in being the first to hear and publicly perform these pieces.\n* The interview concludes with Clara Schumann's letter expressing her desire to mend their friendship, further emphasizing the deep connection and respect within this musical circle."


  const markdownComponents = {
    p: ({ node, children, ...props }) => <p className="my-4" {...props}>{children}</p>,
    li: ({ node, children, ...props }) => <li className="my-2 ml-8 pl-4 list-disc" {...props}>{children}</li>,
    h1: ({ node, children, ...props }) => <h1 className="my-4 text-4xl font-bold text-indigo-600" {...props}>{children}</h1>,
    h2: ({ node, children, ...props }) => <h2 className="my-3 text-2xl font-semibold" {...props}>{children}</h2>,
    // Ensure that strong tags do not mistakenly wrap block-level elements like h1, h2
    strong: ({ node, children, ...props }) => <strong className="text-indigo-600" {...props}>{children}</strong>
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto">
        <div className="mb-8">
          <div className="w-full md:max-w-[560px] mx-auto">
            <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
              <iframe
                src="https://www.youtube.com/embed/mLGIRHCPqN4?si=p-wuk0uwa3r38Bgy"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                className="absolute top-0 left-0 w-full h-full shadow-xl rounded-xl"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="mb-8">
          <div className="text-gray-700 leading-relaxed">
            <ReactMarkdown components={markdownComponents}>{summary}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EibenshuetzAudio;
