import React, { useState } from 'react';
import data from './cpeData.json';

const TopicCloud = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const allTopics = data.flatMap((item) => item.topics);
  const uniqueTopics = [...new Set(allTopics)].sort();

  const openModal = (topic) => {
    setSelectedTopic(topic);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTopic(null);
    setIsModalOpen(false);
  };

  const getPageNumbers = (topic) => {
    return data
      .filter((item) => item.topics.includes(topic))
      .map((item) => item.image.split('.')[0])
      .join(', ');
  };

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-wrap justify-center gap-4">
          {uniqueTopics.map((topic, index) => (
            <div
              key={index}
              className="bg-white rounded-full px-4 py-2 text-sm font-semibold text-indigo-600 shadow-md hover:bg-indigo-600 hover:text-white transition duration-300 ease-in-out transform hover:scale-110 cursor-pointer"
              onClick={() => openModal(topic)}
            >
              {topic}
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white rounded-lg p-8 z-10 max-w-md mx-auto">
            <h2 className="text-center text-2xl font-bold mb-4">{selectedTopic}</h2>
            <p className="mb-4">
              This topic appears on the following pages: {getPageNumbers(selectedTopic)}
            </p>
            <button
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopicCloud;